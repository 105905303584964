<template>
    <div class="modal-content" @click.stop>
        <div class="modal-header">
            <h2>BPMN Modeler</h2>
            <button class="close-button" @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
            <BpmnModeler
                ref="bpmnModeler"
                :processorService="processorService"
                :inlineMode="inlineMode"
                :hasUnsavedChanges="hasUnsavedChanges"
                @node-selected="handleNodeSelected"
                @node-deleted="handleNodeDeleted"
                @save="$emit('save')"
                @model-changed="onModelChanged"
                @factory-reset="onFactoryReset"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BpmnModeler from './BpmnModeler.vue';
import type { BpmnNode } from './types/bpmn';

export default defineComponent({
    name: 'BpmnModelerModal',
    components: {
        BpmnModeler,
    },
    props: {
        processorService: {
            type: Object,
            required: true
        },
        inlineMode: {
            type: Boolean,
            default: false
        },
        hasUnsavedChanges: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close', 'node-selected', 'node-deleted', 'save', 'model-changed', 'factory-reset'],
    setup(props, { emit }) {
        const closeModal = () => {
            emit('close');
        };

        const handleNodeSelected = (node: BpmnNode | null) => {
            console.log('Selected node:', node);
            emit('node-selected', node);
        };

        const handleNodeDeleted = (deletedNode: { id: string }) => {
            console.log('Node was deleted:', deletedNode);
            emit('node-deleted', deletedNode);
        };

        function onModelChanged() {
            console.log('[DEBUG] BpmnModelerModal: re-emitting model-changed');
            emit('model-changed');
        }

        function onFactoryReset() {
            console.log('[DEBUG] BpmnModelerModal: re-emitting factory-reset');
            emit('factory-reset');
        }

        return {
            closeModal,
            handleNodeSelected,
            handleNodeDeleted,
            onModelChanged,
            onFactoryReset
        };
    },
});
</script>

<style scoped>
.modal-content {
    background-color: white;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background: #f9f9f9;
    border-bottom: 1px solid #eee;
}

.modal-body {
    flex: 1;
    overflow: hidden;
    position: relative;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0 8px;
}

h2 {
    margin: 0;
}
</style>