/* eslint-env browser */

<template>
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/bpmn-js.css">
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/diagram-js.css">
  <link rel="stylesheet" href="https://unpkg.com/bpmn-js@17.11.1/dist/assets/bpmn-font/css/bpmn.css">
  <router-view />
  <JoinExistingConversation
    v-if="showJoinConversation"
    :conversations="activeConversations"
    @conversation-selected="handleConversationSelected"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import JoinExistingConversation from './components/JoinExistingConversation.vue';
import { GameStreamingService } from './GameManager';
import './App.vue.css';
import { IGameStreamingService } from './types';
import { AutomabService } from './services/AutomabService';
import { useRouter } from 'vue-router';
import { useAuthStore } from './stores/auth';

// Interface defining the structure of component data
interface ComponentData {
  gameService: IGameStreamingService | null;
  showJoinConversation: boolean;
  activeConversations: Array<{ email: string; conversationId: string }>;
  onConversationSelected: ((conversationId: string | null) => void) | null;
  showJoinConversationHandler: ((event: CustomEvent) => void) | null;
}

export default defineComponent({
  components: {
    JoinExistingConversation
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    return { router, auth };
  },
  data(): ComponentData {
    return {
      gameService: null,
      showJoinConversation: false,
      activeConversations: [],
      onConversationSelected: null,
      showJoinConversationHandler: null
    };
  },
  async created() {
    // Add token expiration handler
    window.addEventListener('tokenExpired', this.handleTokenExpired);
    
    // Initialize GameService early, but don't connect yet
    const urlParams = new URLSearchParams(window.location.search);
    const env = urlParams.get('env');

    if (env) {
      if (env === 'automab') {
        this.auth.automab = true;
      }
    }
    // this.automab = true; // force automab mode always (aitrainer on temporary hiatus)

    function isLocalhost(): boolean {
      return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    }

    let port = 50054;
    if (this.auth.automab && !isLocalhost()) {
      port = 50055;
    }

    console.log("-------------------------------");
    console.log("Automab: ", this.auth.automab);
    console.log("-------------------------------");

    this.gameService = GameStreamingService.initialize(
      port,         // hostPortNum: number
      this.$toast,  // toast: ToastPluginApi
      "",           // userToken: string - empty initially, set after Google auth
      this.auth.automab && !isLocalhost()  // automab: boolean
    );
    if (this.auth.automab) {
      AutomabService.initialize(
        50057,        // hostPortNum: number
        this.$toast,  // toast: ToastPluginApi
        "",          // userToken: string - empty initially, set after Google auth
      );
    }
  },
  mounted() {
    const handleShowJoinConversation = ((event: CustomEvent) => {
      this.activeConversations = event.detail.conversations;
      this.showJoinConversation = true;
      this.onConversationSelected = event.detail.onSelect;
    });

    window.addEventListener('showJoinConversation', handleShowJoinConversation as any);
    this.showJoinConversationHandler = handleShowJoinConversation;
  },
  methods: {
    handleTokenExpired() {
      console.log('Token expired, resetting auth state');
      // Clean up existing connections
      if (this.gameService) {
        GameStreamingService.reset();
      }
      // Reset auth state using store
      this.auth.clearAuth();
      // Navigate to login using router
      this.router.push('/login');
    },
    handleConversationSelected(conversationId: string | null) {
      this.showJoinConversation = false;
      if (this.onConversationSelected) {
        this.onConversationSelected(conversationId);
        this.onConversationSelected = null;
      }
    },
  },
  beforeUnmount() {
    // Remove event listeners
    window.removeEventListener('tokenExpired', this.handleTokenExpired);
    if (this.showJoinConversationHandler) {
      window.removeEventListener('showJoinConversation', this.showJoinConversationHandler as any);
    }
  }
});
</script>
