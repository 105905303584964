import { ToastPluginApi } from 'vue-toast-notification';
import { IWebSocketManager } from './types';

export abstract class WebSocketManager implements IWebSocketManager {
    protected wsConnection: WebSocket | null = null;
    protected connAckd = false;
    protected connectionCounter = 0;
    protected readonly AUTH_KEY = "a692c1cf-c590-49c5-81ef-850afd79d36c";

    constructor(
        protected readonly hostPortNum: number
        , protected readonly toast: ToastPluginApi
        , protected userToken: string) {
    }

    // Add public methods that wrap the protected ones
    public connect(): Promise<void> {
        return this.setupWebSocketConnection();
    }

    public disconnect(): void {
        this.cleanupWebSocket();
    }

    protected getReadyStateString(state: number): string {
        switch (state) {
            case WebSocket.CONNECTING: return 'CONNECTING';
            case WebSocket.OPEN: return 'OPEN';
            case WebSocket.CLOSING: return 'CLOSING';
            case WebSocket.CLOSED: return 'CLOSED';
            default: return `UNKNOWN(${state})`;
        }
    }

    protected getWsUrl(): string {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const hostname = window.location.hostname;
        return `${protocol}//${hostname}:${this.hostPortNum}`;
    }

    protected cleanupWebSocket(): void {
        if (this.wsConnection) {
            const connId = this.connectionCounter;
            console.log(`Cleaning up WebSocket connection #${connId}`, {
                readyState: this.getReadyStateString(this.wsConnection.readyState),
                url: this.wsConnection.url,
                timestamp: new Date().toISOString()
            });

            this.wsConnection.onclose = null;
            this.wsConnection.onerror = null;
            this.wsConnection.onmessage = null;
            this.wsConnection.onopen = null;

            if (this.wsConnection.readyState === WebSocket.OPEN ||
                this.wsConnection.readyState === WebSocket.CONNECTING) {
                this.wsConnection.close();
                console.log(`Explicitly closed WebSocket connection #${connId}`);
            }
            this.wsConnection = null;
        }
        this.connAckd = false;
        this.onCleanup();
    }

    protected abstract onCleanup(): void;
    protected abstract handleMessage(event: MessageEvent): void;
    protected abstract sendConnectRequest(): boolean;

    protected setupWebSocketConnection(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                if (this.wsConnection) {
                    console.warn('Setting up new WebSocket while previous connection exists', {
                        existingReadyState: this.getReadyStateString(this.wsConnection.readyState),
                        existingConnId: this.connectionCounter,
                        timestamp: new Date().toISOString()
                    });
                    this.cleanupWebSocket();
                }

                const connId = ++this.connectionCounter;
                const wsUrl = this.getWsUrl();
                this.wsConnection = new WebSocket(wsUrl);

                console.log(`Creating WebSocket connection #${connId} to ${wsUrl}`, {
                    timestamp: new Date().toISOString()
                });

                this.wsConnection.binaryType = 'arraybuffer';

                this.wsConnection.onclose = (event) => {
                    console.log(`WebSocket connection #${connId} closed`, {
                        code: event.code,
                        reason: event.reason,
                        wasClean: event.wasClean,
                        timestamp: new Date().toISOString()
                    });
                    this.handleEarlyDisconnect(event);
                    this.cleanupWebSocket();
                };

                this.wsConnection.onerror = (event) => {
                    console.error(`WebSocket connection #${connId} error:`, event);
                    this.cleanupWebSocket();
                    reject(new Error('WebSocket connection error'));
                };

                this.wsConnection.onopen = () => {
                    console.log(`WebSocket connection #${connId} opened`, {
                        url: this.wsConnection?.url,
                        timestamp: new Date().toISOString()
                    });
                    if (this.sendConnectRequest()) {
                        this.connAckd = true;
                        resolve();
                    }
                };

                this.wsConnection.onmessage = (event) => {
                    if (this.hostPortNum === 50057) {
                        console.log('Automab message received');
                    }
                    this.handleMessage(event);
                    if (this.connAckd) {
                        resolve();
                    }
                };

            } catch (error) {
                console.error(`Error setting up WebSocket connection #${this.connectionCounter}:`, error);
                this.cleanupWebSocket();
                reject(error);
            }
        });
    }

    isConnected(): boolean {
        return this.wsConnection !== null &&
            this.wsConnection.readyState === WebSocket.OPEN;
    }

    protected handleEarlyDisconnect(_event: CloseEvent): void {
        // Base implementation does nothing
    }
} 
