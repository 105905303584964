import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import LoginScreen from '../LoginScreen.vue';
import DevChat from '../DevChat.vue';
import { RealtimeAudioStreamingService } from '../RealtimeApiAudioManager';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: LoginScreen
    },
    {
        path: '/chat',
        component: DevChat
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Global navigation guard
router.beforeEach((
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const auth = useAuthStore();

    // Allow login route always
    if (to.path === '/login') {
        next();
        return;
    }

    if (!auth.isAuthenticated) {
        console.log('[Router] Not authenticated, redirecting to login');
        next('/login');
        return;
    }

    // For chat specifically, also verify audio service
    if (to.path === '/chat') {
        try {
            RealtimeAudioStreamingService.getInstance();
            next();
        } catch (error) {
            console.log('[Router] Audio service not initialized, redirecting to login');
            next('/login');
        }
        return;
    }

    // Other authenticated routes
    next();
});

export default router;
