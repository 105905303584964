<template>
  <!-- Always render in "inline" style now -->
  <div class="inline-config-container">
    <div class="modal-header">
      <h2>Configuration Editor</h2>
      <button class="close-button" @click="$emit('close')">&times;</button>
    </div>

    <div class="modal-body">
      <div v-if="!configItems || configItems.length === 0">
        Loading...
      </div>
      <div v-else>
        <div class="dropdown-wrapper">
          <label>Node Type:</label>
          <select :value="currentSubtype" @change="onSubtypeChanged($event)">
            <!-- "None" to signify no config -->
            <option value="None">None</option>
            <!-- Dynamically list out subtype mappings -->
            <option
              v-for="(value, key) in allSubtypes"
              :key="key"
              :value="value"
            >
              {{ key }}
            </option>
          </select>
        </div>

        <!-- Show DynamicFormField only if we have a real subtype -->
        <div v-if="currentSubtype !== 'None' && currentItem">
          <form>
            <!-- For each config field, watch changes and emit "config-changed" -->
            <DynamicFormField
              v-for="field in currentItemFields"
              :key="field.name"
              :fieldDef="field"
              v-model:value="currentItem[field.name]"
              @change="onFormFieldChanged"
            />
          </form>
        </div>
        <div v-else>
          <!-- For subtype "None" or no matched item, show blank -->
          <p>No fields to display for subtype: {{ currentSubtype }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineProps, defineEmits, defineExpose } from 'vue';
import DynamicFormField from './DynamicFormField.vue';
import type { BpmnNode } from './types/bpmn';

interface ConfigItem {
  id: string;
  subtype: string;
  [key: string]: any;
}

// Caution these two interfaces are duplicated from server's TypesAnalyzer.ts
// TODO: move to a shared location
// ******************************************
interface DictionaryMetadata {
  keyType: string;
  value: PropertyMetadata;
}

interface PropertyMetadata {
  name: string;
  type: string;
  required: boolean;
  isArray: boolean;
  isEnum: boolean;
  enumValues?: string[];
  childProps?: Record<string, PropertyMetadata>;
  dictionary?: DictionaryMetadata;

  // fields derived from TSDoc comments:
  hidden?: boolean;
  defaultValue?: any;
  enumOptions?: string[];
  uiControl?: string;
  collapsed?: boolean;
}
// ******************************************

interface HeaderConfig {
  taskTypes: Record<string, string>;
  gateTypes: Record<string, string>;
}

const props = defineProps({
  processorService: {
    type: Object,
    required: true,
  },
  selectedNode: {
    type: Object as () => BpmnNode | null,
    default: null,
  },
  show: Boolean,
  inlineMode: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close', 'save', 'config-changed']);

// Our config array loaded at mount:
const configItems = ref<ConfigItem[]>([]);
const metadata = ref<Record<string, PropertyMetadata[]>>({});
const header = ref<HeaderConfig>({ taskTypes: {}, gateTypes: {} });

// Called by DevChat on mount or inline usage, initially loads config...
onMounted(async () => {
  await loadConfigFromServer();
});

async function loadConfigFromServer() {
  const configDataText = await props.processorService.getConfigData();
  const configData = JSON.parse(configDataText.formConfig);

  configItems.value = configData.config;
  header.value = configData.header;
  metadata.value = configData.metadata;
}

// 1) The new method to load default config data.
async function loadDefaultConfig() {
  try {
    const defaultConfigDataText = await props.processorService.getDefaultConfigData();
    const configData = JSON.parse(defaultConfigDataText.formConfig);

    configItems.value = configData.config;
    header.value = configData.header;
    metadata.value = configData.metadata;

    console.log('[ConfigEditorModal] Loaded default config data successfully.');
    emit('config-changed'); // Let parent know we've changed the config
  } catch (error) {
    console.error('Error loading default config data:', error);
  }
}

// All subtype mappings from header
const allSubtypes = computed(() => ({
  // Task subtypes
  ...(header.value.taskTypes || {}),
  // Gate subtypes
  ...(header.value.gateTypes || {})
}));


// Find the config item for currently selected node, if any
const currentItem = computed(() => {
  if (!props.selectedNode) return null;
  return configItems.value.find((c) => c.id === props.selectedNode?.id) || null;
});

// If we have a matched item, use that subtype, else "None"
const currentSubtype = computed(() => {
  return currentItem.value?.subtype || 'None';
});

// Based on currentSubtype, get metadata fields
function findConfigTypeKeyForSubtype(subtype: string): string | null {
  // Example logic searching in your header, as shown in your code
  for (const [configTypeKey, subTypeVal] of Object.entries(header.value.taskTypes)) {
    if (subTypeVal === subtype) return configTypeKey;
  }
  for (const [configTypeKey, subTypeVal] of Object.entries(header.value.gateTypes)) {
    if (subTypeVal === subtype) return configTypeKey;
  }
  return null;
}

const currentItemFields = computed(() => {
  const configTypeKey = findConfigTypeKeyForSubtype(currentSubtype.value);
  if (!configTypeKey) return [];
  // Return the field definitions from metadata
  return metadata.value[configTypeKey] || [];
});

// When the user picks a subtype from the dropdown
function onSubtypeChanged(e: Event) {
  const newSubtype = (e.target as HTMLSelectElement).value;
  const nodeId = props.selectedNode?.id;
  if (!nodeId) return;

  // Check if we already have a config item for this node
  const existingItem = configItems.value.find((c) => c.id === nodeId);

  if (existingItem) {
    // If user picks "None," remove this config entry
    if (newSubtype === 'None') {
      const idx = configItems.value.indexOf(existingItem);
      if (idx !== -1) configItems.value.splice(idx, 1);
    } else {
      // Otherwise just update the subtype
      existingItem.subtype = newSubtype;
      // Optionally fill missing fields with new defaults
      const defaults = createDefaultConfigForSubtype(newSubtype, metadata.value);
      for (const key in defaults) {
        if (existingItem[key] === undefined) {
          existingItem[key] = defaults[key];
        }
      }
    }
  } else {
    // If no config item exists, create a brand-new one if not "None"
    if (newSubtype !== 'None') {
      const newCfg = createDefaultConfigForSubtype(newSubtype, metadata.value);
      // Set the real ID to the node's ID
      newCfg.id = nodeId;
      configItems.value.push(newCfg);
    }
  }

  // We let the parent know config changed
  emit('config-changed');
}

/**
 * Recursively builds an object from a given set of property definitions
 */
function createObjectFromPropertyDefs(
  propertyDefs: PropertyMetadata[]
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const def of propertyDefs) {
    const propName = def.name;

    // Use doc-comment default if present
    if (def.defaultValue !== undefined) {
      result[propName] = def.defaultValue;
      continue;
    }

    // If it's an array
    if (def.isArray) {
      result[propName] = [];
      continue;
    }

    // If it's a dictionary
    if (def.dictionary) {
      result[propName] = {};
      continue;
    }

    // If it's an object with childProps, build recursively
    if (def.childProps && Object.keys(def.childProps).length > 0) {
      const nestedProps = Object.values(def.childProps);
      result[propName] = createObjectFromPropertyDefs(nestedProps);
      continue;
    }

    // If it's an enum
    if (def.isEnum && def.enumValues && def.enumValues.length > 0) {
      result[propName] = def.enumValues[0];
      continue;
    }

    // Otherwise fallback to typed defaults
    switch (def.type) {
      case 'string':  result[propName] = ''; break;
      case 'number':  result[propName] = 0;  break;
      case 'boolean': result[propName] = false; break;
      default:        result[propName] = ''; // unknown type => fallback to string
    }
  }

  return result;
}

/**
 * Creates a new config object for the given subtype, using metadata
 */
function createDefaultConfigForSubtype(
  subtype: string,
  metadataObj: Record<string, PropertyMetadata[]>
): { [key: string]: any; id: string; subtype: string } {
  const configTypeKey = findConfigTypeKeyForSubtype(subtype);

  // If no recognized type key, return minimal
  if (!configTypeKey) {
    return { id: '', subtype };
  }

  // Build the object from the property definitions
  const propertyDefs = metadataObj[configTypeKey] || [];
  const baseObj = createObjectFromPropertyDefs(propertyDefs);

  // Ensure we have an empty ID and the chosen subtype
  baseObj.id = '';
  baseObj.subtype = subtype;

  // Cast so TS knows we have at least .id and .subtype
  return baseObj as { [key: string]: any; id: string; subtype: string };
}

function onFormFieldChanged() {
  // Any time a field changes, let the parent know we have unsaved changes
  emit('config-changed');
}

/**
 * Remove any config entry matching the given BPMN node ID
 */
function removeConfigForNodeId(nodeId: string) {
  const idx = configItems.value.findIndex((c) => c.id === nodeId);
  if (idx !== -1) {
    configItems.value.splice(idx, 1);
    console.log(`Removed config item for nodeId=${nodeId}`);
  }
  emit('config-changed');
}

// We'll rename "saveChanges" to "saveConfig" and NOT call it automatically from a button here:
async function saveConfig() {
  try {
    await props.processorService.saveConfigData(configItems.value);
    console.log('Configuration saved successfully');
    emit('save');
  } catch (error) {
    console.error('Error saving configuration:', error);
  }
}

/**
 * Make this removeConfigForNodeId method callable from the parent.
 */
defineExpose({
  removeConfigForNodeId,
  saveConfig,
  loadDefaultConfig
});
</script>

<style scoped>
.inline-config-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background: #f9f9f9;
  border-bottom: 1px solid #eee;
  flex-shrink: 0;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
}

h2 {
  margin: 0;
}

/* Dropdown and forms */
.dropdown-wrapper {
  margin-bottom: 20px;
}
.dropdown-wrapper select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.form-actions {
  margin-top: 20px;
  text-align: right;
}

.save-button {
  background: #007ACC;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.save-button:hover {
  background: #005fa3;
}

/* Only apply these to the "overlay" view if not inlineMode */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-container {
  background-color: #fff;
  border-radius: 8px;
  width: 90vw;
  max-width: 800px;
  height: 90vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}
</style>
