import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: '',
        userName: '',
        userEmail: '',
        server: '',
        isAuthenticated: false,
        automab: false
    }),
    actions: {
        setAuth(token: string, user: { name: string; email: string }, server: string, automab: boolean) {
            this.token = token;
            this.userName = user.name;
            this.userEmail = user.email;
            this.server = automab ? "automab" : "aitrainer";  // Set server based on automab flag
            this.isAuthenticated = true;
            this.automab = automab;
        },
        clearAuth() {
            this.token = '';
            this.userName = '';
            this.userEmail = '';
            this.server = '';
            this.isAuthenticated = false;
            this.automab = false;
        }
    }
}) 