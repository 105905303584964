<template>
  <div class="join-conversation-overlay" @keydown.enter="selectConversation(null)">
    <div class="join-conversation-modal">
      <h3>Join Conversation</h3>
      <div class="conversation-list">
        <div 
          class="conversation-option default-option" 
          ref="defaultOption"
          tabindex="0"
          @click="selectConversation(null)">
          Start New Conversation
        </div>
        <div 
          v-for="conversation in sortedConversations" 
          :key="conversation.conversationId"
          class="conversation-option"
          tabindex="0"
          @click="selectConversation(conversation.conversationId)">
          Join {{ conversation.email }}'s Conversation
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import type { defineProps, defineEmits } from 'vue';

interface Conversation {
  email: string;
  conversationId: string;
}

const props = defineProps<{
  conversations: Conversation[];
}>();

const emit = defineEmits<{
  (e: 'conversation-selected', conversationId: string | null): void;
}>();

const defaultOption = ref<HTMLElement | null>(null);

const sortedConversations = computed(() => {
  return [...props.conversations].sort((a, b) => a.email.localeCompare(b.email));
});

const selectConversation = (conversationId: string | null) => {
  emit('conversation-selected', conversationId);
};

onMounted(() => {
  // Focus the "Start New Conversation" option by default
  defaultOption.value?.focus();
});
</script>

<style scoped>
.join-conversation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.join-conversation-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
}

.conversation-list {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
}

.conversation-option {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 4px;
  outline: none;
}

.conversation-option:hover {
  background-color: #f5f5f5;
}

.conversation-option:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,.25);
}

.default-option {
  font-weight: bold;
}
</style> 