<template>
    <div class="bpmn-container">
        <div ref="canvas" class="bpmn-canvas"></div>
        <div class="button-container">
            <button @click="factoryReset" class="factory-reset-button" title="Reset to factory default"
                :class="{ 'button-disabled': !canFactoryReset }">
                Factory Reset
            </button>
            <button @click="discardChanges" class="discard-button" :disabled="!hasUnsavedChanges"
                :class="{ 'button-disabled': !hasUnsavedChanges }" title="Discard unsaved changes">
                Discard Changes
            </button>
            <button @click="onSaveClicked" class="save-button" :disabled="!hasUnsavedChanges"
                :class="{ 'button-disabled': !hasUnsavedChanges }" title="Save changes">
                Save
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, defineProps, defineEmits, defineExpose } from 'vue';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule } from 'bpmn-js-properties-panel';

// Import required CSS
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

const props = defineProps({
    processorService: {
        type: Object,
        required: true
    },
    inlineMode: {
        type: Boolean,
        default: false
    },
    hasUnsavedChanges: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['nodeSelected', 'nodeDeleted', 'save', 'model-changed', 'factory-reset']);

const canvas = ref<HTMLDivElement | null>(null);
let modeler: BpmnModeler | null = null;

const currentXML = ref('');
const canFactoryReset = ref(true); // Always enabled since it's a factory reset

/**
 * Load a BPMN diagram from the given XML string.
 */
const loadDiagram = async (xml: string) => {
    if (!modeler) return;
    try {
        await modeler.importXML(xml);
        (modeler.get('canvas') as any).zoom('fit-viewport');
    } catch (err) {
        console.error('Error importing BPMN XML:', err);
        // Fallback if XML could not be loaded
        modeler.createDiagram();
    }
};

onMounted(async () => {
    try {
        const response = await props.processorService.getProcessModelXml();
        currentXML.value = response.processModelXml ?? '';
    } catch (error) {
        console.error('Error fetching BPMN XML:', error);
        currentXML.value = '';
    }

    modeler = new BpmnModeler({
        container: canvas.value!,
        additionalModules: [
            BpmnPropertiesPanelModule,
            BpmnPropertiesProviderModule
        ],
        keyboard: { bindTo: window }
    });

    await loadDiagram(currentXML.value);

    // Explicitly cast these as any to satisfy TS
    const eventBus = modeler.get('eventBus') as any;
    const selection = modeler.get('selection') as any;

    // Selection changed event
    eventBus.on('selection.changed', (e: any) => {
        const selectedElements = selection.get();
        if (selectedElements.length === 1) {
            const element = selectedElements[0];
            emit('nodeSelected', {
                id: element.id,
                type: element.type,
                businessObject: element.businessObject
            });
        } else {
            emit('nodeSelected', null);
        }
    });

    // Mark changes as unsaved
    eventBus.on(['elements.changed', 'elements.delete', 'connect.end', 'shape.move.end'], () => {
        emit('model-changed');
    });

    // Emit nodeDeleted when a node is deleted
    eventBus.on('commandStack.elements.delete.postExecuted', (event: any) => {
        const context = event.context;
        if (context && context.elements) {
            context.elements.forEach((element: any) => {
                if (element.id) {
                    emit('nodeDeleted', { id: element.id });
                }
            });
        }
        emit('model-changed');
    });
});

const factoryReset = async () => {
    if (confirm('Are you sure you want to reset to factory defaults?')) {
        try {
            const response = await props.processorService.getDefaultProcessModelXml();
            if (modeler) {
                await loadDiagram(response.processModelXml ?? '');
                currentXML.value = response.processModelXml ?? '';
                emit('factory-reset'); 
            }
        } catch (error) {
            console.error('Error performing factory reset:', error);
        }
    }
};

const discardChanges = async () => {
    if (confirm('Are you sure you want to discard all unsaved changes?')) {
        try {
            if (modeler) {
                await loadDiagram(currentXML.value);
                emit('save');
            }
        } catch (error) {
            console.error('Error discarding changes:', error);
        }
    }
};

const onSaveClicked = async () => {
  if (!modeler) return;

  try {
    // 1) Export new XML from the diagram
    const { xml } = await modeler.saveXML({ format: true });

    // 2) Save to server
    await props.processorService.setProcessModelXml(xml);

    // 3) Finally emit the 'save' event to tell the parent we are done
    emit('save');
  } catch (error) {
    console.error("Error saving BPMN:", error);
  }
}; 

/**
 * Provide a way for the parent component to get the current BPMN XML
 */
async function exportDiagram(): Promise<string | undefined> {
    if (!modeler) return '';
    const { xml } = await modeler.saveXML({ format: true });
    return xml;
}

defineExpose({ exportDiagram });

onBeforeUnmount(() => {
    if (modeler) {
        modeler.destroy();
    }
});
</script>

<style scoped>
.bpmn-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.bpmn-canvas {
    flex: 1;
    height: 100%;
}

.button-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}

.save-button,
.discard-button,
.factory-reset-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: white;
    font-weight: 500;
}

.save-button {
    background-color: #4CAF50;
}

.discard-button {
    background-color: #ff9800;
}

.factory-reset-button {
    background-color: #f44336;
}

.save-button:hover:not(:disabled):not(.button-disabled) {
    background-color: #45a049;
}

.discard-button:hover:not(:disabled):not(.button-disabled) {
    background-color: #f57c00;
}

.factory-reset-button:hover:not(:disabled):not(.button-disabled) {
    background-color: #da190b;
}

.button-disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.bpmn-container :deep(.bjs-powered-by) {
  display: none !important;
}
</style>